<template>
  <div class="check-box">
    <p>{{ title }}</p>
    <ul class="item-box" :class="isShowMore ? 'isShowMore' : ''">
      <li @click="selectAll" class="hoverstyle" style="margin-right:30px">
        <span :class="activeIndex == '' ? 'active' : ''">全部</span>
      </li>
      <li v-if="activeIndex">
        <span class="active"> {{ activeText }}</span>
        <i @click.stop="selectAll" :class="'el-icon-error close-icon '"></i>
      </li>
      <li v-else v-for="(item, i) in list" :key="i" @mouseover="mouseover(item)" class="hoverstyle">
        <span :class="activeIndex == item.id ? 'active' : ''">
          {{ item[keyname] }}
        </span>
        <div v-if="item.id == hoverActiveIndex" class="pop-container">
          <center>
            <div
              class="hoverstyle"
              v-for="(hoverItem, hoverIndex) in hoverList"
              :key="hoverIndex"
              @click="updateActiveIndex(hoverItem)"
            >
              {{ hoverItem[keyname] }}
            </div>
          </center>
        </div>
      </li>
      <li class="more" v-if="needShowMoreTool" @click="isShowMore = !isShowMore">
        <span v-if="!isShowMore">收起<i :class="'el-icon-arrow-up'"></i></span>
        <i v-if="isShowMore" :class="'el-icon-more'"></i>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['title', 'list', 'activeIndex', 'keyname', 'areaLevel', 'hoverList', 'hoverActiveIndex', 'allList'],
  name: 'checkRadioGroupLarge',
  computed: {
    needShowMoreTool() {
      // 需要动态获取dom 高度去计算
      return true
    },
    activeText() {
      const item = this.allList.filter(item => item.id == this.activeIndex)[0]
      return item?.[this.keyname]
    }
  },
  data() {
    return {
      isShowMore: true
    }
  },
  methods: {
    selectChidLsit(row) {
      this.$emit('selectHoverLsit', row.id)
    },
    selectAll() {
      this.$emit('update:activeIndex', '')
      this.$emit('cancalHoverList')
    },
    mouseover(row) {
      // if (this.activeIndex) {
      //   return
      // }
      this.$emit('changeHoverList', row.id)
      this.$emit('update:hoverActiveIndex', row.id)
    },
    updateActiveIndex(item) {
      this.$emit('update:activeIndex', item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.check-box {
  display: flex;
  padding: 5px 0;
  p {
    width: 160px;
    // margin-right: 20px;
    padding-left: 40px;
    height: 40px;
    font-weight: bold;
    line-height: 40px;
  }
  ul {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    height: auto;
  }
  li {
    position: relative;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    span {
      height: 36px;
      line-height: 36px;
      padding: 5px 22px;
    }
  }
  .active {
    background: #55cbc4;
    color: #fff;
    border-radius: 4px;
  }
}
.item-box {
  position: relative;
  padding-right: 40px;
  li.more {
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
    text-align: center;
    cursor: pointer !important;
  }
}
.isShowMore {
  height: 45px !important;
  // overflow: hidden;
}

.close-icon {
  position: absolute;
  right: -25px;
  top: 8px;
  // margin-left: 15px;
  color: #666666;
}

.pop-box {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.pop-container {
  position: absolute;
  z-index: 99999;
  width: 100vw;
  left: 0;
  center {
    display: flex;
    // transform: translateX(-50%);
    > div {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      background: #f6f6f8;
      line-height: 18px;
      padding: 5px 10px;
    }
  }
}
</style>
