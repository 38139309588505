export const log = {
    info(info, tipsText) {
        console.log(`%c ${tipsText}...Info...`, 'color: green; font-style: italic', info)
    },
    JSON(info, tipsText) {
        console.log(`%c ${tipsText}...JSON...`, 'color: blue; font-style: italic', JSON.stringify(info, null, 2))
    },
    err(tipsText) {
        return err => {
            console.log(`%c ${tipsText}...Error...`, 'color: red; font-style: italic', err)
        }
    }
}