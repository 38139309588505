<template>
  <div class="container" @click="gotoCompanyDetailPage(item)" :class="size == 'mini' ? 'mini' : ''">
    <!-- <el-avatar :size="60" :src="item.smallHeadPicUrl"></el-avatar> -->
    <avaderImg :imgUrl="item.smallHeadPicUrl" :sex="item.gender == 1 ? true : false"></avaderImg>
    <p class="nick-name">
      <span>{{ item.nickName }} </span><span>{{ item.occupation | EmployeeOccupationListFilter }}</span> <span>|</span>
      <span>{{ item.workYears }}年</span>
    </p>
    <p class="text-button">
      <companytag
        v-for="(company, companyIndex) in item.workedCompany"
        :key="companyIndex"
        :text="company | CompanyListFilter"
      >
      </companytag>
    </p>
    <p class="text-info">
      <domaintag v-for="(point, pointIndex) in item.domains" :key="pointIndex" :text="point | EmployeeDomainListFilter">
      </domaintag>
    </p>
    <p class="address">
      <addresstag :text="item.area | AreaListFilter"></addresstag>
    </p>
  </div>
</template>

<script>
export default {
  name: 'persionListItem',
  props: ['item', 'size'],
  methods: {
    gotoCompanyDetailPage(item) {
      this.$router.push({
        path: `/personal/detail/${item.id}`
      })
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 285px;
  height: 280px;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px 20px;
  // padding-left: 15px;
  margin-bottom: 20px;
  box-shadow: 0px 7px 8px 0px rgba(229, 225, 225, 0.2);
  cursor: pointer;
}

.container:hover {
  box-shadow: 0px 7px 8px 0px rgba(229, 225, 225, 0.5);
}

.mini {
  width: 245px;
  height: 100%;
}

.img-box {
  position: relative;
}

.nick-name {
  margin-top: 10px;
  margin-bottom: 16px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 25px;

  span {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    margin-right: 5px;
  }
}

.text-button {
  margin-bottom: 16px;

  /deep/ .el-tag {
    margin-right: 10px;
  }
}

.text-info {
  margin-bottom: 15px;
  line-height: 30px;
}

.address {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5ad3cd;
  line-height: 20px;
}
</style>
