<template>
  <div class="group-button">
    <span :class="['no-customization', valueList === '' ? 'select' : '']" @click="changeValue('')">无定制开发业务</span>
    <el-divider direction="vertical"></el-divider>
    <el-checkbox-group v-model="checkValue" class="app-category">
      <el-checkbox-button
        v-for="item in businessList.softwareDevelopmentList"
        :label="item.id"
        :key="item.id"
        v-if="!needCompare || (needCompare && needCompare.id == item[needCompare.itemKey])"
        >{{ item.name }}</el-checkbox-button
      >
    </el-checkbox-group>
  </div>
</template>

<script>
import {getBusinessListApi} from '@/api'
export default {
  props: ['valueList', 'needCompare'],
  name: 'newButtonCheckGroup',
  data() {
    return {
      businessList: {}
    }
  },
  computed: {
    checkValue: {
      set(val) {
        this.$emit('update:valueList', val)
      },
      get() {
        return this.valueList || []
      }
    }
  },
  async created() {
    await this.getBusinessList()
  },
  methods: {
    async getBusinessList() {
      const res = await getBusinessListApi()
      this.businessList = res.data
    },
    changeValue() {
      this.$emit('update:valueList', '')
    },
    toggleCheck(item) {
      try {
        let valueList
        if (!this.valueList.includes(item.id)) {
          valueList = [...this.valueList, item.id]
        } else {
          valueList = [...(this.valueList.filter(i => item.id != i) || [])]
        }
        this.$emit('update:valueList', valueList)
      } catch (err) {}
    }
  }
}
</script>

<style lang="scss" scoped>
.no-customization {
  width: 160px;
  height: 36px;
  border-radius: 18px;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #999999;
  line-height: 34px;
  border: 1px solid #dedede;
  box-sizing: border-box;
  vertical-align: top;
}
.select {
  background: #5ad3cd;
  border: 1px solid #5ad3cd;
  color: #fff;
}
/deep/ .el-divider--vertical {
  height: 36px;
  margin: 0 20px;
}
/deep/ .el-checkbox-button {
  margin-right: 10px;
  border-radius: 15px;
  border: 0;
  input {
    border: 0;
  }
  span {
    height: 36px;
    border-left: 1px solid #5ad3cd;
    border-radius: 20px !important;
    border: 1px solid #dedede;
    color: #999999;
    box-sizing: border-box;
    line-height: 34px;
    padding: 0 28px;
  }
}

.group-button {
  .is-checked {
    /deep/ .el-checkbox-button__inner {
      color: #ffffff;
      background-color: #5ad3cd !important;
      border-color: #5ad3cd;
    }
  }
}
.app-category {
  display: inline-block;
  vertical-align: top;
}

/deep/ .group-button .el-checkbox-button__inner {
  height: 35px;
  line-height: 36px !important;
  background: #5ad3cd !important;
  border: 1px solid #5ad3cd;
  font-size: 16px;
  box-sizing: border-box;
}
</style>
