<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {
  getAreaListApi,
  getBusinessListApi,
  getEmployeeDomainListApi,
  getEducationListApi,
  getfamousInternetCompanyApi,
  getFamousInternetCompanyShortNameListApi,
  getEmployeeOccupationListApi,
  getEmployeeStationListApi,
  getEmployeeOccupationDomainClassRelationListApi,
  getEmployeeDomainClassListApi
} from '@/api'
export default {
  computed: {
    ...mapState([
      'UserInfo',
      'AreaList',
      'BusinessList',
      'EducationList',
      'EmployeeDomainList',
      'FamousInternetCompanyShortNameList'
    ])
  },
  async created() {
    // const baseData = localStorage.getItem('AreaList')
    // let AreaList = baseData ? JSON.parse(baseData) : []
    await this.getAreaList()
    await this.getBusinessList()
    await this.getEducationList()
    await this.getEmployeeDomainList()
    await this.getfamousInternetCompany()
    await this.getEmployeeOccupationList()
    await this.getEmployeeStationList()
    await this.getEmployeeDomainClassListApi()
    await this.getEmployeeOccupationDomainClassRelationListApi()
    await this.getFamousInternetCompanyShortNameListApi()
    // let timer = setInterval(() => {
    //   if (this.$route.path !== '/login') {
    //     if (!AreaList.length) {
    //       timer = null
    //       location.reload()
    //       return
    //     }
    //   }
    // }, 300)
  },
  methods: {
    async getAreaList() {
      const res = await getAreaListApi()
      this.$store.commit('ChangeAreaList', [...(res?.data || [])])
    },
    async getBusinessList() {
      const res = await getBusinessListApi()
      console.log('res?.data', res?.data)
      this.$store.commit('ChangeBusinessList', res.data || {})
    },
    async getEducationList() {
      const res = await getEducationListApi()
      this.$store.commit('ChangeEducationList', [...(res?.data || [])])
    },
    async getEmployeeDomainList() {
      const res = await getEmployeeDomainListApi()
      this.$store.commit('ChangeEmployeeDomainList', [...(res?.data || [])])
    },

    async getfamousInternetCompany() {
      const res = await getfamousInternetCompanyApi()
      this.$store.commit('ChangeCompanyList', [...(res?.data || [])])
    },
    async getFamousInternetCompanyShortNameListApi() {
      const res = await getFamousInternetCompanyShortNameListApi()
      this.$store.commit('ChangeFamousInternetCompanyShortNameList', [...(res?.data || [])])
    },
    // 职业列表
    async getEmployeeOccupationList() {
      const res = await getEmployeeOccupationListApi()
      this.$store.commit('ChangeEmployeeOccupationList', [...(res?.data || [])])
    },
    async getEmployeeStationList() {
      const res = await getEmployeeStationListApi()
      this.$store.commit('ChangeEmployeeStationList', [...(res?.data || [])])
    },
    async getEmployeeDomainClassListApi() {
      const res = await getEmployeeDomainClassListApi()
      this.$store.commit('ChangeEmployeeDomainClassList', [...(res?.data || [])])
    },
    async getEmployeeOccupationDomainClassRelationListApi() {
      const res = await getEmployeeOccupationDomainClassRelationListApi()
      this.$store.commit('ChangeEmployeeOccupationDomainClassRelationList', [...(res?.data || [])])
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  min-height: 100%;
  background: #f6f6f8;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
<style>
.el-card {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0);
  box-shadow: none !important;
  border: none !important;
}
</style>
