import {getUserInfoApi} from '../api/index'
export default {
  // async created() {
  //   await this.getCurrentUserInfo()
  // },
  methods: {
    async getCurrentUserInfo() {
      const UserInfoString = localStorage.getItem('UserInfo')
      const UserInfo = UserInfoString ? JSON.parse(UserInfoString) : {}
      if (UserInfo.phonenumber) {
        const res = await getUserInfoApi({phone: UserInfo.phonenumber})
        this.$store.commit('ChangeUserInfo', res?.data || {})
        // return res?.data || {}
      } else {
        // return {}
      }
    }
  }
}
