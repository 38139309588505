<template>
  <div class="dialog-container">
    <h4>{{ submitType == 1 ? '您需要做个什么项目？' : '您想要引荐的客户信息是？' }}</h4>
    <center>
      <el-form ref="ruleForm009" :model="form" :rules="rules">
        <el-form-item style="width:560px;margin-top:40px" class="b-bottom">
          <el-select v-model="form.businessId" placeholder="请选择活动区域" style="width:560px">
            <el-option :label="item.name" :value="item.id" v-for="item in typeList" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="phoneNumber" class="bg-input" style="width:560px;margin-top:40px">
          <el-input
            style="width:560px"
            v-model="form.phoneNumber"
            :placeholder="submitType == 1 ? '您的手机号' : '客户的手机号'"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="juzhong" style="margin-top:50px">
        <el-button
          type="primary"
          style="width: 370px;height: 46px;background: #5AD3CD;border-radius: 4px;"
          @click="sureFreePublish"
          >{{ submitType == 1 ? '免费发布' : '提交引荐' }}</el-button
        >
      </div>

      <div slot="footer" class="dialog-footer">
        <div class="warning-box" :class="{right_bomttom: submitType != 2}">
          <i class="el-icon-warning-outline"></i>
          <span v-if="submitType == 2">
            您提交引荐后，客户经理会与您和您引荐的客户联系。 当最终成单后，您将获得全倾本单收入的20%作为佣金</span
          >
          <span v-else> 需求提交后，会有专属客户经理与您联系</span>
        </div>
      </div>
    </center>
  </div>
</template>

<script>
import {projectIntentionPushApi} from '../api/background'
import {mapState} from 'vuex'

export default {
  name: 'marketing',
  computed: {
    ...mapState(['UserInfo', 'BusinessList'])
  },
  props: {
    submitType: {
      type: Number,
      default() {
        return 1 // 入口类型
      }
    },
    DialogVisible: {
      type: Boolean
    }
  },
  created() {},
  data() {
    const phone = (rule, value, callback) => {
      let regs = /^((13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}$/
      if (!value) {
        return callback(new Error('请输入手机号'))
      }
      setTimeout(() => {
        if (!regs.test(value)) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      }, 1000)
    }
    return {
      form: {
        businessId: 1,
        phoneNumber: '',
        isSelfSubmit: 0,
        isUserRecommend: 0, // TODO: 需要调整一些参数，根据情况  推荐的，引荐的 自己发布的
        isPartnerRecommend: 0,
        recommenderPartnerName: '',
        recommenderUserId: undefined,
        userId: undefined
        //TODO:  手机号与用户手机号相等 ？ 客户需求  ：  引荐需求
        //  iframe 需求 这是伙伴引荐
      },
      rules: {
        phoneNumber: [{validator: phone, trigger: 'blur'}]
      },
      typeList: [
        {id: 1, name: '网站建设', type: '定制开发'},
        {id: 2, name: 'app开发', type: '定制开发'},
        {id: 3, name: '小程序开发', type: '定制开发'}
      ]
    }
  },
  methods: {
    sureFreePublish() {
      this.$refs.ruleForm009.validate(valid => {
        if (valid) {
          this.projectIntentionPush()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    projectIntentionPush() {
      const enums = {
        1: 'isSelfSubmit',
        2: 'isUserRecommend',
        3: 'isPartnerRecommend'
      }
      this.form[enums[this.submitType]] = 1
      if (this.submitType == 1) {
        this.form.userId = this.UserInfo.id
      } else {
        this.form.recommenderUserId = this.UserInfo.id
      }
      projectIntentionPushApi({...this.form})
        .then(res => {
          this.$dialog.toast('发布成功')
          this.$emit('update:visible', false)
        })
        .catch(err => {
          console.log('%c 发布需求 err....', 'color: red; font-style: italic', JSON.stringify(err, null, 2))
        })
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-footer {
  position: relative;
  height: 80px;
  .right_bomttom {
    position: absolute;
    right: -150px;
    bottom: -60px;
  }
}
.warning-box {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 560px;
  height: 60px;
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
  margin: 0 auto;
  position: relative;
  margin-top: 50px;
  i {
    position: relative;
    left: -10px;
  }
}
.dialog-container {
  padding: 40px 100px;
  /deep/ .el-form-item__label {
    font-size: 18px !important;
    padding: 0 20px 0 0;
  }

  .bg-input {
    background: #f7f4f8;
    /deep/ .el-input__inner {
      background: #f7f4f8;
      border: none;
    }
  }
  .b-bottom {
    border: none;
    border-radius: 0;
    /deep/ .el-input__inner {
      border: none;
      border-bottom: 1px solid #f7f4f8;
      border-radius: 0;
    }
  }
}

h4 {
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 33px;
  position: relative;
  top: -50px;
}
</style>
