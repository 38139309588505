<template>
  <div class="title-container">
    <h4 class="left-border">{{ currentTitle }}</h4>
    <span></span>
    <h4 v-if="backTitle" style="color:#5ad3cd;" @click="goback">{{ backTitle }} ></h4>
    <h4 v-if="titleInfo" class="titleInfo"><i class="el-icon-warning-outline"></i> {{ titleInfo }}</h4>
  </div>
</template>

<script>
export default {
  props: ['currentTitle', 'backTitle', 'titleInfo'],
  methods: {
    goback() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
div {
  display: flex;
  span {
    flex: 1;
  }
}
h4 {
  height: 24px;
  border: 0;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
  margin-left: -30px;
}
.left-border {
  border-left: 3px solid #5ad3cd;
  padding-left: 10px;
}

.title-container {
  margin-bottom: 35px;
  width: 1050px;
}

.titleInfo {
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
</style>
