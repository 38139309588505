<template>
  <div class="check-box">
    <p>{{ title }}</p>
    <ul class="item-box">
      <li class="hoverstyle" style="margin-bottom: 15px;">
        <span class="active box">
          {{ contentText }}
        </span>
        <i @click.stop="callBack" :class="'el-icon-error close-icon '"></i>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['title', 'contentText'],
  name: 'checkRadioOnlyone',
  methods: {
    callBack() {
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss" scoped>
.check-box {
  display: flex;
  padding: 5px 0;

  p {
    width: 160px;
    // margin-right: 20px;
    padding-left: 40px;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
  }

  ul {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    height: auto;
  }

  li {
    position: relative;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    margin-right: 20px;
  }

  .active {
    background: #55cbc4;
    color: #fff;
    border-radius: 4px;
  }
}

.box {
  height: 36px;
  line-height: 36px;
  padding: 5px 22px;
}

.item-box {
  position: relative;
  padding-right: 40px;

  li.more {
    position: absolute;
    right: -20px;
    top: 0;
    width: 100px;
    text-align: center;
    cursor: pointer !important;
  }
}

.isShowMore {
  height: 45px !important;
  overflow: hidden;
}

.close-icon {
  position: absolute;
  right: -25px;
  top: 8px;
  // margin-left: 15px;
  color: #666666;
  z-index: 1000;
}

.pop-box {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.children-content {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 40px 36px;
}

.sub-title {
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 25px;
  margin-bottom: 20px;
  cursor: pointer;
}

.sub-title span {
  max-width: 124px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.point {
  width: 8px;
  height: 8px;
  background: #5ad3cd;
  margin: 7px 8px 0 0;
  display: inline-block;
  border-radius: 50%;
  vertical-align: top;
}

.children-option {
  width: 141px;
  margin-right: 10px;
}

.children-name {
  margin-bottom: 20px;
  display: inline-block;
  overflow: hidden;
  width: 141px;
}

.option-name {
  display: inline-block;
  max-width: 99px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  vertical-align: top;
}

.hot {
  width: 36px;
  height: 18px;
  background: rgba(255, 114, 76, 0.1);
  border-radius: 10px;
  border: 1px solid #ff724c;
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ff724c;
  line-height: 16px;
  text-align: center;
  display: inline-block;
  margin-left: 6px;
  vertical-align: top;
}

.clild-name {
  padding: 0 !important;
}

.clild-name1 {
  padding: 5px 22px;
}

/deep/.el-popover {
  padding: 0 !important;
}
</style>
<style>
.popover {
  box-shadow: 6px 12px 10px 0px rgba(203, 203, 203, 0.36) !important;
  border-radius: 10px !important;
  padding: 0 !important;
}

.pleasehloder {
  height: 36px;
  width: 100%;
  background: linear-gradient(180deg, #ffffff 0%, #e3e3e3 33%, #ffffff 68%, #ffffff 100%);
  opacity: 0.2;
  margin-top: -6px;
}</style>
