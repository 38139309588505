<template>
  <div class="avader-box" :style="`width: ${size}px;height: ${size}px`">
    <el-avatar :size="size" :src="imgUrl"></el-avatar>
    <img :src="sex ? boyimg : girlimg" alt="" class="sex" :class="sex ? 'boy' : 'girl'" />
  </div>
</template>

<script>
export default {
  props: {
    imgUrl: {
      type: String
    },
    sex: {
      type: Boolean,
      default() {
        return true
      }
    },
    size: {
      type: Number,
      default() {
        return 60
      }
    }
  },
  data() {
    return {
      boyimg: require('../assets/boy.png'),
      girlimg: require('../assets/girl.png')
    }
  }
}
</script>

<style scoped lang="scss">
.avader-box {
  position: relative;
  margin-top: 5px;
  //;
}
.sex {
  position: absolute;
  top: 0%;
  right: 0%;
  width: 35%;
  height: 35%;
}
</style>
