<template>
  <div class="upload-box">
    <template v-if="fileList && fileList[0]">
      <p v-for="(item, i) in fileList" :key="i">
        <img class="img" :src="item" alt="ss" />
        <i @click="deletePic(i)" class="el-icon-error" style="color:#FF773A"></i>
      </p>
    </template>
    <el-upload
      :on-success="uploadCallBack"
      :headers="{token}"
      :show-file-list="false"
      list-type="picture-card"
      name="picFile"
      action="/api/file/upload"
    >
      <i slot="default" class="el-icon-plus"></i>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: 'uploadTemplate',
  created() {
    this.token = localStorage.getItem('TOKEN')
  },
  props: ['fileList'],
  methods: {
    uploadCallBack(res) {
      this.$emit('uploadCallBack', res)
    },
    deletePic(i) {
      this.$emit('deletePic', i)
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-box {
  display: flex;
  margin-top: 25px;
  flex-wrap: wrap;
}

p {
  width: 150px;
  height: 150px;
  margin-right: 50px;
  position: relative;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  i {
    position: absolute;
    right: -11px;
    top: -11px;
    font-size: 20px;
  }
}
</style>
