<template>
  <div class="headerBox">
    <div class="header-container">
      <div class="topHeader">
        <div class="left active">全倾</div>
        <div class="middle">
          <p :class="{active: isActiveRoute(item)}" v-for="(item, i) in baseData" :key="i" @click="linkTo(item)">
            {{ item.name }}
          </p>
        </div>
        <div class="space"></div>
        <div class="right">
          <div>
            <button class="button-enter hoverstyle" v-if="UserInfo && UserInfo.id" @click="$router.push('/enter')">
              入驻
            </button>
            <button class="button-style hoverstyle" v-if="!UserInfo || !UserInfo.id" @click="$router.push('/login')">
              登录
            </button>
            <button
              class="button-style hoverstyle"
              v-if="!UserInfo || !UserInfo.id"
              @click="$router.push({path: '/login', query: {submitType: 3}})"
            >
              注册
            </button>
            <el-popover placement="bottom-end" width="80" trigger="hover" v-if="UserInfo && UserInfo.phonenumber">
              <p>
                <el-button type="text" @click="$router.push('/my')">个人中心</el-button>
              </p>
              <p>
                <el-button type="text" @click="$router.push('/orderList')">我的订单</el-button>
              </p>
              <p>
                <el-button type="text" @click="loginOut">退出</el-button>
              </p>
              <span slot="reference" size="mini">
                <el-avatar
                  style="position:relative;top:10px"
                  v-if="UserInfo && UserInfo.smallHeadpicUrl"
                  :size="30"
                  :src="UserInfo.smallHeadpicUrl"
                ></el-avatar>
                {{ (UserInfo && UserInfo.nickname) || UserInfo.phonenumber }}
                <img src="../assets/vip.png" class="vip-icon" alt="" srcset="" v-if="hasEffected" />
              </span>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <div class="search-container">
      <div class="searchHeader">
        <div class="left-logo">一站企服平台</div>
        <div class="middle-input">
          <el-input :placeholder="placeholder" v-model="Input" class="input-with-select"> </el-input>
          <button class="searchButton" @click="toResultPage()"><img :src="searchImg" alt="" /></button>
        </div>
        <div class="right-publish" @click="DialogVisible = true">免费发布需求</div>
      </div>
    </div>
    <div class="activity" v-if="activityData && activityData.couponItems && activityData.couponItems.length > 0 && activityData.picUrl">
      <img :src="activityData.picUrl" alt="" srcset="" style="width: 100%;">
      <div class="coupon-list">
        <div :class="`coupon ${item.receiveCount > 0 ? 'coupon-bg' : 'coupon-bg-disabled'}`" v-for="item in activityData.couponItems" :key="item.id" @click="handleGetCoupon(item)">
          <div class="coupon-price">¥ {{ item.value }}</div>
          <div class="coupon-content">
            <div class="coupon-type">{{ item.type === '无门槛券' ? item.type : item.type === '满减券' ? `满 ${item.subtractAmount}` : `每满 ${item.subtractAmount}`}}</div>
            <div class="coupon-num">{{ item.receiveCount === 0 ? '已领完' : `${item.receiveCount} 张` }}</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="DialogVisible" width="1000px">
      <marketing :submitType="1" :visible.sync="DialogVisible"></marketing>
    </el-dialog>
    <el-dialog title="请选择您的入驻身份" :visible.sync="EnterDialogVisible" width="1200" center>
      <div style="width:100%">
        <el-form ref="form" :model="form" label-width="120px" class="enter-box">
          <el-form-item label="请选择您的身份">
            <el-select v-model="form.type">
              <el-option label="公司" value="1"></el-option>
              <el-option label="团队" value="2"></el-option>
              <el-option label="个人" value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <p class="juzhong">
          <el-button
            style="width: 370px;height: 46px;background: #5AD3CD;border-radius: 4px;"
            type="primary"
            @click="nextStep"
            >下一步
          </el-button>
        </p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import marketing from './marketing'
import {checkVip, getEffectiveActivityArea} from '../api/product'
import {
  handleGetCouponApi
} from '../api/personal'


export default {
  name: 'commonHeader',
  components: {marketing},
  data() {
    return {
      searchImg: require('../assets/search.png'),
      DialogVisible: false,
      EnterDialogVisible: false,
      placeholder: '',
      Input: '',
      form: {
        type: '1'
      },
      hasEffected: false,
      baseData: Object.freeze([
        {name: '首页', link: '/'},
        {name: '公司', link: '/company'},
        {name: '团队', link: '/teams'},
        {name: '个人', link: '/personal'},
        {name: '项目', link: '/project'},
        {name: '知识', link: '/techVideo'},
        {name: '产品', link: '/product'},
        {name: '服务', link: '/serve'},
        // {name: '技术视频', link: '/subjectList'},
        {name: '技术标准', link: '/quanqingStandard'}
        // {name: '技术产品', link: '/quanqingProduct'}
        // {name: '后台', link: '/greenRoom'},
        // {name: '关于全倾', link: '/quanqingAbout'}
        // {name: '我的', link: '/my'}
      ]),
      activityData: {}
    }
  },
  props: ['activityFrom'],
  computed: {
    ...mapState(['UserInfo', 'BusinessList', 'SearchInput'])
  },
  created() {
    this.Input = this.SearchInput
    checkVip().then(res => {
      this.hasEffected = res.data?.hasEffected || false
    })
  },
  watch: {
    SearchInput() {
      this.Input = this.SearchInput
    }
  },
  mounted() {
    let timer = 0
    console.log('%c [ ["公司列表页","知识列表页","质检包页","首页"].includes(this.form) ]-148-「commonHeader」', 'font-size:px;', ['公司列表页','知识列表页','质检包页','首页'].includes(this.activityFrom))
    console.log('%c [ this.form ]-149-「commonHeader」', 'font-size:px;', this.activityFrom)
    if (['公司列表页','知识列表页','质检包页', '首页'].includes(this.activityFrom)) {
      getEffectiveActivityArea({
        showPosition: this.activityFrom
      }).then(res => {
        this.activityData = res.data
      })
    }
    
    setInterval(() => {
      this.placeholder = this.BusinessList?.softwareDevelopmentList[timer % 3]?.name
      timer++
    }, 2000)
  },
  methods: {
    handleGetCoupon(item) {
      const params = {
        couponId: item.id,
        activityId: this.activityData.id
      }
      handleGetCouponApi(params).then(res => {
        if (res.code === 200) {
          getEffectiveActivityArea({
            showPosition: this.activityFrom
          }).then(res => {
            this.activityData = res.data
          })
          this.$message.success('领取成功')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    isActiveRoute(item = {}) {
      return this.$route.path.split('?')[0] == item.link
    },
    linkTo(option) {
      this.$store.commit('ChangeSearchInput', '')
      this.$router.push(option.link)
    },
    nextStep() {
      const enums = {
        1: '/companyEnter',
        2: '/teamEnter',
        3: '/personalEnter'
      }
      this.$router.push(enums[this.form.type])
    },
    loginOut() {
      localStorage.removeItem('UserInfo')
      localStorage.removeItem('TOKEN')
      // this.$router.go(0)
      location.reload()
    },
    toResultPage() {
      this.$store.commit('ChangeSearchInput', this.Input)
      if (['/home'].includes(this.$route.fullPath)) {
        this.$router.push('/result')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.headerBox {
  width: 100%;
  // height: 130px;

  // background: #000;
  .header-container {
    width: 100%;
    height: 50px;
    background: #000;
  }

  .search-container {
    width: 100%;
    height: 80px;
    background: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  /deep/ .el-dialog {
    border-radius: 10px;
  }
}

.topHeader {
  width: 1200px;
  margin: 0 auto;
  height: 50px;
  line-height: 50px;
  display: flex;
  color: #fff;
  text-align: center;

  .left {
    // font-weight: bold;
    // width: 260px;
    font-size: 25px;
  }

  .middle {
    display: flex;

    p {
      margin-left: 47px;
      cursor: pointer;
    }
    p:first-child {
      margin-left: 50px;
    }

    p:hover {
      color: #5ad3cd;
    }
  }

  .space {
    flex: 1;
  }

  .right {
    // height: 50px;
    // line-height: 50px;
    display: flex;
    // padding-top: 17px;
  }
}

.searchHeader {
  background: #fff;
  display: flex;
  height: 80px;
  width: 1200px;
  margin: 0 auto;
  line-height: 40px;
  padding: 20px 0;

  .left-logo {
    width: 160px;
  }

  .middle-input {
    display: flex;
    width: 860px;
    margin-left: 20px;
    margin-right: 40px;

    // border: 1px solid #5ad3cd;
    input {
      border: none;
      outline: none;
    }
  }

  .right-publish {
    cursor: pointer;
    background: #ff724c;
    border-radius: 3px;
    color: #fff;
    width: 120px;
    height: 40px;
    text-align: center;
    font-size: 16px;
  }
}

.vip-icon {
  width: 14px;
  height: 12px;
  margin-left: 6px;
  display: inline-block;
  cursor: pointer;
}

.active {
  color: #37c2bc;
}

.popover-box {
  width: 100px;
}

.juzhong {
  width: 100%;
  // display: flex;
  margin: 0 auto;
  text-align: center;
}

.enter-box {
  width: 100%;
  height: 150px;
}

.button-style {
  border-radius: 20px;
  border: 1px solid #37c2bc;
  background: none;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  padding: 3px 25px;
  margin-left: 15px;
}

.searchButton {
  width: 85px;
  height: 40px;
  background: #5ad3cd;
  border: none;
  outline: none;
}

.button-enter {
  width: 80px;
  height: 26px;
  background: #5ad3cd;
  border-radius: 2px;
  border: none;
  outline: none;
  color: #fff;
  margin-right: 15px;
}
.activity {
  width: 100%;
  position: relative;
}
.coupon-list {
  position: absolute;
  right: 20px;
  bottom: 10px;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  flex-direction: row;
}
.coupon-bg {
  cursor: pointer;
  background-image: url(/img/userinfo-icon/coupon-bg.png);
  background-size: 100% 100%;
}
.coupon-bg-disabled {
  background-image: url(/img/userinfo-icon/coupon-bg.png);
  background-size: 100% 100%;
}
.coupon {
  margin-right: 20px;
  width: 140px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.coupon-price {
  height: 100%;
  width: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
}
.coupon-content {
  flex: 1;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  
}
.coupon-type {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50%;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.coupon-num {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  justify-content: flex-start;
  height: 50%;
}
</style>

<style lang="scss">
.headerBox {
  /deep/ .input-with-select {
    border-color: #5ad3cd !important;
  }

  /deep/ .el-input {
    border-color: #5ad3cd !important;
  }

  /deep/ .el-input-group {
    border-color: #5ad3cd !important;
  }

  /deep/ .el-input-group--append {
    border-color: #5ad3cd !important;
  }

  /deep/ .el-input__inner {
    border-color: #5ad3cd !important;
  }

  /deep/ .el-button,
  .el-button--default {
    background: #5ad3cd !important;
  }

  /deep/ .el-dialog {
    width: 1200px;
  }

  /deep/ .el-dialog__header {
    width: 1200px;
  }
}
</style>
