<template>
  <div class="container" @click="gotoTeamsDetailPage(item)" :class="size == 'mini' ? 'mini' : ''">
    <el-image style="width: 100%; height: 171px" :src="item.smallLogoPicUrl" :fit="'cover'"></el-image>
    <p class="title">
      <b> {{ item.name }}</b
      ><span v-if="item.memberNum"> | {{ item.memberNum }}人</span>
    </p>
    <p class="text-button">
      <companytag
        v-for="(company, companyIndex) in item.memberCompanyList"
        :key="companyIndex"
        :text="company | CompanyListFilter"
      >
      </companytag>
    </p>
    <p class="address">
      <addresstag :text="item.provinceId | AreaListFilter" type="gray"></addresstag>
    </p>
  </div>
</template>

<script>
export default {
  name: 'teamListItem',
  props: ['item', 'size'],
  methods: {
    gotoTeamsDetailPage(item) {
      this.$router.push({
        path: `/teams/detail/${item.id}`
        // query: {
        //   id: item.id,
        //   detail: JSON.stringify(item)
        // }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 285px;
  height: 350px;
  background: #ffffff;

  border-radius: 8px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;

  p {
    padding-left: 15px;
  }
}

.container:hover {
  box-shadow: 0px 7px 8px 0px rgba(229, 225, 225, 0.5);
}

.mini {
  width: 245px;
  height: 100%;
}

.title {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 25px;

  span {
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
}

.text-button {
  margin-bottom: 15px;
}

.address {
  button {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
}
</style>
